<template>
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="ml-5">
        <v-btn
          href="javascript:window.print()"
          class="title elevation-0 mr-2"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-printer</v-icon>&nbsp; พิมพ์
        </v-btn>
        <v-btn
          @click.stop="Export2Doc('exportContent', 'word-content-1-4')"
          class="title elevation-0"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>
    <div id="exportContent">
      <div class="page">
        <div align="center">
          <img width="150" height="150" src="ovec.png" />
          <br />
          <div class="head">
            เอกสารแสดงประวัติ
          </div>
          <div class="head">
            ข้าราชการครูและบุคลากรทางการศึกษา
          </div>

          <br />

                 <div class="pa-5">
            <img
              v-if="users.personnel_temporary_pic"        
              height="300"
              style="border-radius: 8px"
              :src="'/HRvecfiles/' + users.personnel_temporary_pic"
            />
            <img
              v-else
              width="200"
              src="/HRvecfiles/blank-human-image.png"
              style="border-radius: 30px"
            />
          </div>

          <div class="head">
            {{ users.frist_names }}
          </div>
          <div class="head">ตำแหน่ง : {{ users.position_name }}</div>
          <div class="head">เลขที่ตำแหน่ง : {{ users.id_position }}</div>
          <div class="head">
            {{ users.college_name }}
          </div>
        </div>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->

        <div class="font-weight-bold">
          ประวัติส่วนตัว
        </div>
        <div class="text_j text_al">
          ชื่อ-สกุล {{ users.frist_names }} ตำแหน่ง
          {{ users.position_name }} เลขที่ตำแน่ง
          {{ users.id_position }} วิทยฐานะ {{ users.rang_name }} วุฒิการศึกษา
          {{ users.ed_abb }} สาขาวิชา {{ users.ed_name }} วันเดือนปีเกิด
          {{ users.brith_day }}-{{ users.brith_month }}-{{
            users.brith_year
          }}
          วันเดือนปีที่บรรจุ {{ users.appoin_day }}-{{ users.appoin_month }}-{{
            users.appoin_year
          }}
          ปีที่เกษียณ {{ users.retrire_year }} เบอร์โทร {{ users.tel_p }} E-mail
          {{ users.e_mail }}
        </div>

        <div class="font-weight-bold">
          ใบอนุญาตประกอบวิชาชีพครู
        </div>
        <div class="text_al">
          เลขที่ {{ users.pt_licence }} วันที่ออกบัตร
          {{
            users.pt_ondate | moment("add", "543 years") | moment("D MMMM YYYY")
          }}
          วันที่บัตรหมด
          {{
            users.pt_enddate
              | moment("add", "543 years")
              | moment("D MMMM YYYY")
          }}
        </div>

        <div class="font-weight-bold" v-if="users.pt_licencedr">
          ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา
        </div>
        <div v-if="users.pt_licencedr" class="text_al">
          เลขที่ {{ users.pt_licencedr }} วันที่ออกบัตร
          {{
            users.pt_ondatedr
              | moment("add", "543 years")
              | moment("D MMMM YYYY")
          }}
          วันที่บัตรหมด
          {{
            users.pt_enddatedr
              | moment("add", "543 years")
              | moment("D MMMM YYYY")
          }}
        </div>

        <div class="font-weight-bold">
          ประวัติการศึกษา
        </div>

        <div>
          <v-data-table
            color="success"
            :headers="headersEdu"
            :items="personnel_educations"
           
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.id_branch }}</td>
                <td>{{ item.name_branch }}</td>
                <td>{{ item.name_sub_branch }}</td>
                <td>{{ item.education_level }}</td>
                <td>{{ item.faculty_name }}</td>
                <td>{{ item.branch_name }}</td>
                <td>{{ item.academy_name }}</td>
                <td>{{ item.year_finish }}</td>
                <td>{{ item.academic_results }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <div class="font-weight-bold">
          ประวัติการปฏิบัติหน้าที่
        </div>

        <div>
          <v-data-table
            color="success"
            :headers="headersDuty"
            :items="personnel_dutys"
           
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">{{ item.college_name }}</td>
                <td class="text-center">{{ item.personnel_dutyYear }}</td>
                <td>{{ item.personnel_dutyOrderID }}</td>
                <td>{{ item.personnel_dutyTopic }}</td>
                <td class="text-center">
                  {{
                    item.personnel_dutyDateTime
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <div class="font-weight-bold">
          อายุราชการ
        </div>

        <div class="text_j text_al">
          <div>วันที่บรรจุ : {{ dmy_app_cal }}</div>
          <div>อายุราชการ : {{ get_gov_Age }}</div>

          <div>
            วันที่ ปฏิบัติงาน ณ สถานศึกษา ปัจจุบัน :
            {{
              users.date_app_now
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </div>
          <div>อายุราชการ ณ สถานศึกษาปัจจุบัน : {{ get_govMonth_Age }}</div>
        </div>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->

        <div class="font-weight-bold">
          ผลงาน/รางวัล/การได้รับการยกย่อง
        </div>

        <div>
          <v-data-table
            :headers="headersAward"
            :items="personnel_awards"
           
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">{{ item.personnel_awardYear }}</td>
                <td class="text-center">{{ item.performance_name }}</td>
                <td class="text-center">{{ item.performance_location }}</td>
                <td class="text-center">
                  {{
                    item.performance_time
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </td>
                <td class="text-center">
                  <span v-if="item.award_level == 'college'"
                    >ระดับวิทยาลัย</span
                  >
                  <span v-if="item.award_level == 'provincial'"
                    >ระดับสำนักงานอาชีวศึกษาจังหวัด</span
                  >
                  <span v-if="item.award_level == 'sector'">ระดับภาค</span>
                  <span v-if="item.award_level == 'vocational'"
                    >ระดับอาชีวศึกษา</span
                  >
                  <span v-if="item.award_level == 'nation'">ระดับชาติ</span>
                  <span v-if="item.award_level == 'word'">ระดับโลก</span>
                </td>
                <td class="text-center">
                  <span v-if="item.type_performance == 'skill'"
                    >การแข่งขันทักษะ</span
                  >
                  <span v-if="item.type_performance == 'innovation'"
                    >การประกวดสิ่งประดิษฐ์</span
                  >
                  <span v-if="item.type_performance == 'award'"
                    >รางวัลเชิดชูเกียรติ</span
                  >
                  <span v-if="item.type_performance == 'etc'"
                    >อื่นๆ ( {{ item.personnel_awardEtc }} )
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <div class="font-weight-bold">
          การพัฒนาตนเองและพัฒนาวิชาชีพ
        </div>
        <div>
          <v-data-table
            :headers="headersExper"
            :items="personnel_experiences"
           
          >
          </v-data-table>
        </div>

        <div class="font-weight-bold">
          ประวัติการรับราชการ
        </div>
        <div>
          <v-data-table
            :headers="headersHis"
            :items="personnel_work_historys"
           
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-center">
                  {{ index + 1 }}
                </td>
                <td class="text-center">
                  {{ item.id_position_old }}
                </td>
                <td>
                  <span v-if="item.government_status === '0'"
                    >ไม่ได้ช่วยราชการ</span
                  >
                  <span v-if="item.government_status === '1'">ช่วยราชการ</span>
                </td>
                <td>
                  {{
                    item.date_begin
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </td>
                <td>
                  {{ item.college_name_work }}
                </td>
                <td>
                  {{
                    item.date_end
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY")
                  }}
                </td>
                <td>
                  {{ item.position_s }}
                </td>
                <td>
                  {{ item.special_duty }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "HRvecResume",

  data() {
    return {
      ApiKey: "HRvec2021",
      users: [],
      personnel_educations: [],
      personnel_dutys: [],
      personnel_awards: [],
      personnel_experiences: [],
      personnel_work_historys: [],
      headersEdu: [
        { text: "#", align: "center", value: "number_row" },
        { text: "รหัสประเภทสาขา", align: "center", value: "id_branch" },
        { text: "ประเภทสาขาวิชา สอศ", align: "left", value: "name_branch" },
        {
          text: "วุฒิการศึกษาตรงตามระบบ",
          align: "left",
          value: "name_sub_branch"
        },
        { text: "ระดับการศึกษา", align: "center", value: "education_level" },
        { text: "คณะวิชา", align: "center", value: "faculty_name" },
        {
          text: "สาขาวิชาที่จบการศึกษา",
          align: "center",
          value: "branch_name"
        },
        { text: "จบจาก", align: "center", value: "academy_name" },
        { text: "ปีที่จบ", align: "center", value: "year_finish" },
        { text: "ผลการเรียน", align: "center", value: "academic_results" }
      ],

      headersDuty: [
        { text: "#", align: "center", value: "index" },
        { text: "สถานศึกษา", align: "center", value: "personnel_dutyCollege" },
        { text: "ปี", align: "left", value: "personnel_dutyYear" },
        { text: "คำสั่งที่", align: "left", value: "personnel_dutyOrderID" },
        {
          text: "ชื่อโครงการ/กิจกรรมในสถานศึกษา",
          align: "left",
          value: "personnel_dutyTopic"
        },
        { text: "วันที่", align: "center", value: "personnel_dutyDateTime" }
      ],

      headersAward: [
        { text: "#", align: "center", value: "index" },
        { text: "ปี", align: "center", value: "personnel_awardYear" },
        { text: "ผลงาน รางวัล", align: "center", value: "performance_name" },
        { text: "สถานที่", align: "center", value: "performance_location" },
        { text: "เมื่อวันที่", align: "center", value: "performance_time" },
        { text: "ระดับ", align: "center", value: "award_level" },
        { text: "ประเภท", align: "center", value: "type_performance" }
      ],

      headersExper: [
        { text: "ปี", align: "center", value: "pexperienceyear_s" },
        { text: "รายการ", align: "left", value: "course_name" },
        { text: "สถานที่", align: "left", value: "course_location" },
        { text: "หน่วยงานที่จัด", align: "left", value: "agency_name" },
        { text: "วันที่เริ่มต้น", align: "center", value: "date_time_begin" },
        { text: "วันที่สิ้นสุด", align: "center", value: "date_time_end" },
        { text: "จำนวนชั่วโมง", align: "center", value: "number_hours" }
      ],

      headersHis: [
        { text: "#", align: "center", value: "number_row" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position_old" },
        { text: "สถานะช่วยราชการ", align: "left", value: "government_status" },
        { text: "วันที่", align: "left", value: "date_begin" },
        { text: "สถานที่", align: "left", value: "college_name_work" },
        { text: "สิ้นสุด", align: "center", value: "date_end" },
        { text: "ตำแหน่ง", align: "center", value: "position_s" },
        { text: "หน้าที่อื่น", align: "left", value: "special_duty" }
      ]
    };
  },

  async mounted() {
    await this.personnel_temproraryQuery();
    await this.personnel_educationQuery();
    await this.personnel_dutyQuery();
    await this.personnel_awardQuery();
    await this.personnel_experienceQuery();
    await this.personnel_work_historyQuery();
  },

  methods: {
    async personnel_temproraryQuery() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.id_card
      });
      this.users = result.data;
    },
    async personnel_educationQuery() {
      let result = await this.$http.post("personnel_education.php", {
        ApiKey: this.ApiKey,
        id_card: this.id_card
      });
      this.personnel_educations = result.data;
    },

    async personnel_dutyQuery() {
      let result = await this.$http.post("personnel_duty.php", {
        ApiKey: this.ApiKey,
        personnel_dutyIDCard: this.id_card
      });
      this.personnel_dutys = result.data;
    },

    async personnel_awardQuery() {
      let result = await this.$http.post("personnel_award.php", {
        ApiKey: this.ApiKey,
        personnel_awardid_card: this.id_card
      });
      this.personnel_awards = result.data;
    },

    async personnel_experienceQuery() {
      let result = await this.$http.post("personnel_experience.php", {
        ApiKey: this.ApiKey,
        pexperienceid_card: this.id_card
      });
      this.personnel_experiences = result.data;
    },

    async personnel_work_historyQuery() {
      let result = await this.$http.post("personnel_work_history.php", {
        ApiKey: this.ApiKey,
        id_card: this.id_card
      });
      this.personnel_work_historys = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    id_card() {
      return this.$route.query.id_card;
    },

    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    },
    user_status_type() {
      let user_status = this.users.user_status;
      let result;
      if (user_status == "teacher") {
        result = "ข้าราชการครู";
      } else if (user_status == "director") {
        result = "ผู้อำนวยการวิทยาลัย";
      } else if (user_status == "se_director") {
        result = "รองผู้อำนวยการวิทยาลัย";
      }
      return result;
    },
    dmy_app_cal() {
      let month = this.users.appoin_month;
      let day = this.users.appoin_day;
      let year = this.users.appoin_year - 543;
      let result = month + "/" + day + "/" + year;
      return result;
    },
    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },

    cal_age_gov() {
      const today = new Date();
      const birthDate = new Date(this.dmy_app_cal);
      const yearsDifference = today.getFullYear() - birthDate.getFullYear();
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        return yearsDifference - 1;
      }
      return yearsDifference;
    },

    get_govMonth_Age() {
      let DOB = new Date(this.users.date_app_now);
      var today = new Date(),
        result = {
          years: 0,
          months: 0,
          days: 0,
          toString: function() {
            return (
              (this.years ? this.years + " ปี " : "") +
              (this.months ? this.months + " เดือน " : "") +
              (this.days ? this.days + " วัน" : "")
            );
          }
        };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },

    get_gov_Age() {
      let today = new Date();
      let DOB = new Date(
        parseInt(this.users.appoin_year) -
          543 +
          "-" +
          this.users.appoin_month +
          "-" +
          this.users.appoin_day
      );
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    }
  }
};
</script>

<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.text_al {
  text-indent: 40px;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1.5cm;
  padding-bottom: 1.5cm;
  padding-left: 2cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
      height: 247mm;
    }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
        font-size: 18px;
        font-weight: bold;
      }
      .regular12 {
        font-size: 12px;
      }
      .blod12 {
        font-size: 12px;
        font-weight: bold;
      }
      .blackRegula8 {
        font-size: 8px;
      } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
